import { AngularEditorConfig } from '@kolkov/angular-editor';

import { environment } from 'src/environments/environment';

export const collections = {
  NOTIFICATIONS: 'notifications',
  SERVER_EVENTS: 'server-events',
  APP_CONFIG: 'app-config',
};

export const routes = {
  HOME: 'home',
  PEOPLE: 'people',
  COMPANY_ADMIN: 'admin',
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  SLACK_MESSAGE: 'slack-message',
  CUSTOMERS: 'customers',
  DASHBOARD: 'dashboard',
  OUTBOUND: 'outbound',
  INBOX: 'inbox',
  SETTINGS: 'settings',
  TICKET_TYPES: 'ticket-types',
  AUTOMATIONS: 'automations',
  COLLABORATOR_TYPES: 'collaborator-types',
};

export const INBOX_TYPES = {
  COLLABORATING_ON: 'collaborating-on',
  ALL: 'all',
  ME: 'me',
  UNASSIGNED: 'unassigned',
  FOLLOWING: 'following',
  CLOSED: 'closed',
  EMAIL: 'email',
  IN_APP: 'chat',
  MICROSOFT_TEAMS: 'microsoft-teams',
  SLACK: 'slack',
};

export const PLAN_DATE_STATUS = {
  POLLING: 'polling',
  CONFIRMED: 'confirmed',
};

export const PLAN_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

export enum PlanListType {
  CURRENT,
  PAST,
}

export const PLAN_VENUE_STATUS = {
  SEARCHING: 'searching',
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
};

export const AUTH_CONTEXT = {
  DEFAULT: 'default',
  RSVP: 'rsvp',
  FOLLOW: 'follow',
  REDIRECT: 'redirect',
};

export const AUTH_PROVIDERS = {
  OKTA: 'okta',
  GSUITE: 'gsuite',
  PASSWORD: 'password',
};

export const RSVP_STATUS = {
  WAITING: 'waiting',
  MAYBE: 'maybe',
  DECLINED: 'declined',
  ATTENDING: 'attending',
  INTERESTED: 'interested',
  NOT_INTERESTED: 'not_interested',
  WAITLISTED: 'waitlisted',
};

export const EXPORT_HEADERS_PLANS = [
  'id',
  'title',
  'date',
  'location',
  'createdAt',
  'description',
  'timezone',
  'createdBy',
  'organizerId',
  'attendingCount',
];

export const EXPORT_HEADERS_GUESTS = ['id', 'name', 'email', 'phoneNumber', 'rsvp', 'createdAt', 'planId', 'isHost'];

export const EXPORT_HEADERS_ORGANIZERS = [
  'id',
  'name',
  'category',
  'description',
  'followerCount',
  'createdAt',
  'createdBy',
];

export const CHAT_NOTIFICATION_PREFERENCE = {
  ALL: 'all',
  OFF: 'off',
  HOST_ONLY: 'host-only',
};

export const GUEST_LIST_SHOWN = {
  ALWAYS: 'always',
  RSVPED: 'rsvped',
  NEVER: 'never',
};

export const POST_TYPE = {
  TEXT: 'text',
  SURVEY: 'survey',
};

export const POST_STATUS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
  IN_REVIEW: 'in_review',
};

export const POST_REACTION_OPTIONS = [
  {
    label: 'Like',
    value: 'like',
    icon: 'thumbs-up',
  },
  {
    label: 'Dislike',
    value: 'dislike',
    icon: 'thumbs-down',
  },
  {
    label: 'Insightful',
    value: 'insightful',
    icon: 'bulb',
  },
  {
    label: 'Heart',
    value: 'heart',
    icon: 'heart',
  },
  {
    label: 'Exciting',
    value: 'exciting',
    icon: 'rocket',
  },
];

export const COMMUNITY_CATEGORIES = [
  {
    displayName: 'Club',
    key: 'club',
  },
  {
    displayName: 'Cultural group',
    key: 'erg',
  },
  {
    displayName: 'Team',
    key: 'team',
  },
  {
    displayName: 'Department',
    key: 'department',
  },
  {
    displayName: 'Office',
    key: 'office',
  },
  {
    displayName: 'Non-profit',
    key: 'nonProfit',
  },
  {
    displayName: 'Resource',
    key: 'resource',
  },
  {
    displayName: 'Project',
    key: 'project',
  },
  {
    displayName: 'Cohort',
    key: 'cohort',
  },
  {
    displayName: 'Venue',
    key: 'venue',
  },
  {
    displayName: 'Other',
    key: 'other',
  },
];

// This is kinda duplicated from above – should do this better
export const COMMUNITY_CATEGORY_MAP = {
  club: 'Clubs',
  erg: 'Cultural groups',
  team: 'Teams',
  cohort: 'Cohorts',
  department: 'Departments',
  office: 'Offices',
  venue: 'Venues',
  other: 'Other',
  nonProfit: 'Non-profits',
  resource: 'Resources',
  project: 'Projects',
};

export const COMMUNITY_MEMBER_LIST_PRIVACY = {
  ALWAYS: 'always',
  MEMBERS: 'members',
  ADMINS: 'admins',
};

export const CUSTOM_FIELD_TYPE_SHORT_ANSWER = 'short-answer';
export const CUSTOM_FIELD_TYPE_LONG_ANSWER = 'long-answer';
export const CUSTOM_FIELD_TYPE_CHECKBOX = 'checkbox';
export const CUSTOM_FIELD_TYPE_SINGLE_SELECT = 'single-select';
export const CUSTOM_FIELD_TYPE_MULTI_SELECT = 'multi-select';
export const CUSTOM_FIELD_TYPE_FILE_UPLOAD = 'file-upload';
export const CUSTOM_FIELD_TYPE_USER_ATTRIBUTE = 'user-attribute';
export const CUSTOM_FIELD_TYPE_USER_SELECT = 'user-select';
export const CUSTOM_FIELD_TYPE_MULTI_USER_SELECT = 'multi-user-select';
export const CUSTOM_FIELD_TYPE_DATE = 'date';

export const HOME_FEED_FILTERS = {
  all: {
    display: 'All upcoming',
    icon: 'pulse',
    key: 'all',
  },
  // announcements: {
  //     display: 'Announcement',
  //     icon: 'megaphone',
  //     key: 'announcements',
  // },
  posts: {
    display: 'Discussions',
    icon: 'chatbox-ellipses',
    key: 'posts',
  },
  ergEvents: {
    display: 'Cultural events',
    icon: 'globe',
    key: 'ergEvents',
  },
  clubEvents: {
    display: 'Club events',
    icon: 'basketball',
    key: 'clubEvents',
  },
  pastEvents: {
    display: 'Past events',
    icon: 'reload-circle',
    key: 'pastEvents',
  },
};

export const UPLOAD_STATUS = {
  NONE: 'none',
  PROCESSING: 'processing',
  FAILED: 'failed',
  SUCCESS: 'success',
};

export const TIME_INTERVAL_SECONDS = {
  ONE_HOUR: 3600,
  ONE_DAY: 86400,
  TWO_DAYS: 172800,
  ONE_WEEK: 604800,
};

export const MAX_FILE_SIZE_MB = 5; // MB

export const ANGULAR_EDITOR_CONFIG_DEFAULT: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Enter text here...',
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['undo', 'redo', 'strikeThrough', 'fontName', 'subscript', 'superscript', 'justifyFull'],
    [
      'fontSize',
      'unlink',
      'insertHorizontalRule',
      'backgroundColor',
      'removeFormat',
      'insertVideo',
      'toggleEditorMode',
    ],
  ],
};

export const USER_NOTIFICATION_TYPES = {
  NEW_INVITE: 'newInvite',
  EVENT_UPDATED: 'planUpdated',
  EVENT_CANCELED: 'planCanceled',
  EVENT_CHAT_RECEIVED: 'chatReceived',
  GUEST_RSVP_UPDATED: 'rsvpUpdated',
  GUEST_SURVEY_UPDATED: 'surveyUpdated',
  COMMUNITY_MEMBER_FOLLOW: 'communityMemberFollow',
  COMMUNITY_MEMBER_UNFOLLOW: 'communityMemberUnfollow',
  COMMUNITY_MEMBER_UPLOAD_SUCCEEDED: 'communityMemberUploadSucceeded',
  COMMUNITY_MEMBER_UPLOAD_FAILED: 'communityMemberUploadFailed',
  SURVEY_RESPONSE_UPDATED: 'surveyResponseUpdated',
  OUTBOUND_SURVEY_RECEIVED: 'outboundSurveyResponseReceived',
  CREATE_CUSTOMER_FAILED: 'createCustomerFailed',
  CREATE_CUSTOMER_SUCCEEDED: 'createCustomerSucceeded',
  EXTERNAL_TASK_COMPLETED: 'externalTaskCompleted',
  BULK_CONVERSATION_UPDATE_SUCCEEDED: 'bulkConversationUpdateSucceeded',
  BULK_CONVERSATION_UPDATE_FAILED: 'bulkConversationUpdateFailed',
  OUTBOUND_SEND_SUCCEEDED: 'outboundSendSucceeded',
  OUTBOUND_SEND_FAILED: 'outboundSendFailed',
};

export const ZOOM_OAUTH_URL = 'https://zoom.us/oauth/authorize';
export const ZOOM_OAUTH_REDIRECT_URL = `${environment.baseUrl}/integrations/zoom`;
export const ZOOM_AUTH_CODE_PARAM = 'code';

export const VIDEOCONFERENCE_PROVIDERS = {
  ZOOM: 'zoom',
  MEET: 'hangoutsMeet',
};

export const INTEGRATION_TYPES = {
  CRM: 'external-crm',
  TASK_MANAGER: 'external-task-management',
  AUTOMATION: 'automation',
  TICKETING: 'external-support',
  COMMUNICATION: 'communication',
  KNOWLEDGEBASE: 'external-knowledgebase',
  HRIS: 'hris',
};

export const INTEGRATION_TYPE_NAMES = {
  [INTEGRATION_TYPES.CRM]: 'CRM',
  [INTEGRATION_TYPES.TASK_MANAGER]: 'Task Management',
  [INTEGRATION_TYPES.AUTOMATION]: 'Automation',
  [INTEGRATION_TYPES.TICKETING]: 'Ticketing',
  [INTEGRATION_TYPES.COMMUNICATION]: 'Communication',
  [INTEGRATION_TYPES.KNOWLEDGEBASE]: 'Docs',
  [INTEGRATION_TYPES.HRIS]: 'HR Tools',
};

export const INTEGRATIONS = {
  slack: {
    type: INTEGRATION_TYPES.COMMUNICATION,
    unthreadEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fslack.jpg?alt=media&token=8d943478-d2d8-48d9-9846-74033cadd1f7',
    url: 'slack',
    key: 'slack',
    name: 'Slack',
    preview: 'Slack is a key integration to manage customer conversations and assign tickets to your team',
    connect: 'Connect my Slack account',
    installable: true,
  },
  email: {
    type: INTEGRATION_TYPES.COMMUNICATION,
    unthreadEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Femail.jpg?alt=media&token=465697f9-4691-42d0-8d9f-7dda67011398',
    url: 'email',
    key: 'email',
    name: 'Email',
    preview:
      'Forward incoming emails to an internal Slack channel, discuss internally, and compose your reply right from Slack',
    connect: 'Configure',
    installable: true,
  },
  widget: {
    type: INTEGRATION_TYPES.COMMUNICATION,
    unthreadEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fchat.jpg?alt=media&token=9922df67-15c0-4edd-9fda-15a8a1c9f0ec',
    url: 'in-app-chat',
    key: 'widget',
    name: 'In-app Chat Widget',
    preview:
      'Embed live chat into your application, and messages will be posted into a Slack channel where your team can reply',
    connect: 'Configure',
    installable: true,
  },
  microsoftTeams: {
    type: INTEGRATION_TYPES.COMMUNICATION,
    unthreadEnabled: true,
    isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fteams.jpg?alt=media&token=7bd743dc-65dc-48c9-9e91-0481a7e6df3a',
    url: 'microsoft-teams',
    key: 'microsoft-teams',
    name: 'Microsoft Teams',
    preview:
      'Forward incoming messages in Microsoft Teams channels to an internal Slack channel, discuss internally, and compose your reply right from Slack',
    connect: 'Configure',
    installable: true,
  },
  zapier: {
    type: INTEGRATION_TYPES.AUTOMATION,
    unthreadEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fzapier.jpg?alt=media&token=6472fc0f-53e7-4c51-9499-2c2e053b337c',
    url: 'service-accounts',
    key: 'zapier',
    name: 'Zapier',
    preview: 'Zapier is a powerful no-code / low-code tool to link your Unthread account to thousands of applications',
    connect: 'Connect to Zapier',
    installable: true,
  },
  make: {
    type: INTEGRATION_TYPES.AUTOMATION,
    unthreadEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fmake.png?alt=media&token=001b6588-390e-406e-93a7-d708beb8ecb5',
    url: 'service-accounts',
    key: 'make',
    name: 'Make',
    preview: 'Make is a powerful no-code / low-code tool to link your Unthread account to thousands of applications',
    connect: 'Connect to Make',
    // isBeta: true,
    installable: false,
  },
  webhooks: {
    type: INTEGRATION_TYPES.AUTOMATION,
    unthreadEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fwebhooks.jpg?alt=media&token=5e58829a-128a-49d8-a553-e5a0cb9ec716',
    url: 'webhooks',
    key: 'webhooks',
    name: 'API & Webhooks',
    preview:
      'Built powerful, custom workflows by integrating with the Unthread API and Webhooks for real-time changes and updates',
    connect: 'Enable webhooks',
    installable: true,
  },
  ai: {
    type: INTEGRATION_TYPES.COMMUNICATION,
    unthreadEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fai.jpg?alt=media&token=ea532bce-8818-4c86-93b9-3d12ea630313',
    url: 'ai',
    key: 'ai',
    name: 'AI-Generated Support',
    preview: 'Use the latest in generative AI to automatically generate responses and categorize your customer issues',
    connect: 'Enable AI',
    installable: true,
  },
  hubspot: {
    type: INTEGRATION_TYPES.CRM,
    unthreadEnabled: true,
    // isBeta: true,
    isNango: true,
    registerIntegration: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fhubspot.jpg?alt=media&token=8fde6960-b18c-4f02-805e-aad733594c83',
    // url: 'hubspot',
    key: 'hubspot',
    name: 'Hubspot',
    preview: 'Sync conversations from Slack to Hubspot, and link your Hubspot customers to your customer channels',
    connect: 'Connect to Hubspot',
    installable: true,
    documentation: 'https://docs.unthread.io/integrations/hubspot',
    userInput: [
      {
        label: 'HubSpot object type to sync with (companies, deals)',
        key: 'objectType',
        required: false,
        type: 'dropdown',
        default: 'companies',
        options: [
          {
            label: 'Companies',
            value: 'companies',
          },
          {
            label: 'Deals',
            value: 'deals',
          },
        ],
      },
      {
        label: 'HubSpot object to create for new conversation (call, email)',
        key: 'engagementType',
        required: false,
        type: 'dropdown',
        default: 'call',
        options: [
          {
            label: 'Call',
            value: 'call',
          },
          {
            label: 'Email',
            value: 'email',
          },
          {
            label: 'SMS',
            value: 'sms',
          },
        ],
      },
      {
        label: 'Slack Channel ID field in HubSpot (to enable auto-syncing)',
        key: 'slackChannelId',
        required: false,
        format: 'snakeCase',
        helper:
          "Look in HubSpot for the ID of this field, which should be formatted with underscores, e.g. 'slack_channel_id'",
      },
      {
        label: 'Sync Primary Support Rep from HubSpot?',
        key: 'syncPrimarySupportRep',
        default: true,
        type: 'checkbox',
      },
      {
        label: 'What field in HubSpot maps to Primary Support rep in Unthread?',
        key: 'primarySupportRepField',
        required: false,
        default: 'hubspot_owner_id',
        format: 'snakeCase',
        helper:
          "Look in HubSpot for the ID of this field, which should be formatted with underscores; e.g. 'hubspot_owner_id'",
      },
    ],
  },
  jira: {
    type: INTEGRATION_TYPES.TASK_MANAGER,
    unthreadEnabled: true,
    // isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fjira.jpg?alt=media&token=c6987224-d314-423c-a482-d17c3656b4f4',
    isNango: true,
    registerIntegration: true,
    // url: 'jira',
    key: 'jira',
    name: 'JIRA',
    preview:
      'Automatically create JIRA issues from your Slack conversations and track statuses from the Unthread inbox',
    connect: 'Connect to JIRA',
    userInput: [
      {
        label: 'Project Keys - Comma-separated list of project keys to sync, e.g. "PROJ1,PROJ2"',
        key: 'projectKeys',
        required: true,
      },
      {
        label: 'Sync Slack messages to issue as comments?',
        key: 'syncMessagesToTask',
        default: false,
        type: 'checkbox',
      },
    ],
    documentation: 'https://docs.unthread.io/integrations/task-managers',
    installable: true,
  },
  linear: {
    type: INTEGRATION_TYPES.TASK_MANAGER,
    unthreadEnabled: true,
    // isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Flinear.jpg?alt=media&token=9dab2fbf-4f32-4e86-8fd0-3286cf33e8b3',
    isNango: true,
    registerIntegration: true,
    // url: 'linear',
    key: 'linear',
    name: 'Linear',
    preview:
      'Automatically create Linear issues from your Slack conversations and track statuses from the Unthread inbox',
    connect: 'Connect to Linear',
    userInput: [
      {
        label: 'Post task status changes to triage thread?',
        key: 'postStatusToTriage',
        default: true,
        type: 'checkbox',
      },
      {
        label: 'Move conversation to open when task completed?',
        key: 'moveToOpenOnComplete',
        default: false,
        type: 'checkbox',
      },
    ],
    documentation: 'https://docs.unthread.io/integrations/task-managers',
    installable: true,
  },
  shortcut: {
    type: INTEGRATION_TYPES.TASK_MANAGER,
    unthreadEnabled: true,
    // isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fshortcut.jpg?alt=media&token=ed5c75a9-270a-499f-8de6-99113257cbc5',
    registerIntegration: true,
    // url: 'shortcut',
    key: 'shortcut',
    name: 'Shortcut',
    preview:
      'Automatically create Shortuct stories from your Slack conversations and track statuses from the Unthread inbox',
    connect: 'Connect to Shortuct',
    userInput: [
      {
        label: 'API Token',
        key: 'apiToken',
        type: 'secret',
        required: true,
      },
      {
        label: 'Post task status changes to triage thread?',
        key: 'postStatusToTriage',
        default: true,
        type: 'checkbox',
      },
      {
        label: 'Move conversation to open when task completed?',
        key: 'moveToOpenOnComplete',
        default: false,
        type: 'checkbox',
      },
    ],
    documentation: 'https://docs.unthread.io/integrations/task-managers',
    installable: true,
  },
  asana: {
    type: INTEGRATION_TYPES.TASK_MANAGER,
    unthreadEnabled: true,
    // isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fasana.jpg?alt=media&token=bb7bc15c-bb3e-4d08-9385-386c9ba6a4b7',
    // url: 'asana',
    key: 'asana',
    name: 'Asana',
    title: 'Track tasks on Asana',
    bullets: [
      "Post events & reminders to your community's Slack channel",
      'Share posts and other updates instantly',
      'Sync your Slack members with your community member list',
    ],
    preview:
      'Automatically create Asana tasks from your Slack conversations and track statuses from the Unthread inbox',
    connect: 'Connect to Asana',
    isNango: true,
    registerIntegration: true,
    installable: true,
    documentation: 'https://docs.unthread.io/integrations/task-managers',
    userInput: [
      {
        label: 'Asana Project Names (comma-separated)',
        key: 'projectNames',
        required: true,
      },
      {
        label: 'Post task status changes to triage thread?',
        key: 'postStatusToTriage',
        default: true,
        type: 'checkbox',
      },
      {
        label: 'Move conversation to open when task completed?',
        key: 'moveToOpenOnComplete',
        default: false,
        type: 'checkbox',
      },
    ],
  },
  github: {
    type: INTEGRATION_TYPES.TASK_MANAGER,
    unthreadEnabled: true,
    // isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fgithub.jpg?alt=media&token=e0ece699-b344-48bb-b26c-762f2812a59a',
    // url: 'github',
    key: 'github',
    name: 'GitHub',
    title: 'Track issues on GitHub',
    bullets: [],
    preview:
      'Automatically create GitHub issues from your Slack conversations and track statuses from the Unthread inbox. You must be a GitHub organization owner in order to install this integration.',
    connect: 'Connect to GitHub',
    registerIntegration: true,
    installable: true,
    isNango: true,
    documentation: 'https://docs.unthread.io/integrations/task-managers',
    userInput: [
      {
        label: 'GitHub Organization Name',
        key: 'orgName',
        required: true,
      },
    ],
  },
  monday: {
    type: INTEGRATION_TYPES.TASK_MANAGER,
    unthreadEnabled: true,
    // isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fmonday.com.png?alt=media&token=fdccc74a-8552-425d-ba47-a8e0289f1fb3',
    // url: 'asana',
    key: 'monday',
    name: 'Monday.com',
    title: 'Track tasks on Monday.com',
    bullets: [
      "Post events & reminders to your community's Slack channel",
      'Share posts and other updates instantly',
      'Sync your Slack members with your community member list',
    ],
    preview:
      'Visit the <a target="_blank" href="https://auth.monday.com/oauth2/authorize?client_id=6b9aa39056a740d17a9d64b38f7e7d19&response_type=install"/>Unthread app installation page</a> to install our app in your Monday.com workspace. Automatically create Monday.com tasks from your Slack conversations and track statuses from the Unthread inbox.',
    connect: 'Connect to Monday.com',
    isNango: true,
    registerIntegration: true,
    installable: true,
    documentation: 'https://docs.unthread.io/integrations/task-managers',
    userInput: [
      {
        label: 'Monday.com Board IDs (comma-separated)',
        key: 'boardIds',
        required: true,
      },
      {
        label: 'Post task status changes to triage thread?',
        key: 'postStatusToTriage',
        default: true,
        type: 'checkbox',
      },
      {
        label: 'Move conversation to open when task completed?',
        key: 'moveToOpenOnComplete',
        default: false,
        type: 'checkbox',
      },
    ],
  },
  height: {
    type: INTEGRATION_TYPES.TASK_MANAGER,
    unthreadEnabled: true,
    // isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fheight.jpg?alt=media&token=4ee9de3f-0292-42da-b54e-aa8fcf46cfb8',
    // url: 'height',
    key: 'height',
    name: 'Height',
    title: 'Track tasks on Height',
    bullets: [],
    preview:
      'Automatically create Height tasks from your Slack conversations and track statuses from the Unthread inbox',
    connect: 'Connect to Height',
    installable: true,
    registerIntegration: true,
    documentation: 'https://docs.unthread.io/integrations/task-managers',
    userInput: [
      {
        label: 'API Token',
        key: 'apiToken',
        type: 'secret',
        required: true,
      },
      {
        label: 'Post task status changes to triage thread?',
        key: 'postStatusToTriage',
        default: true,
        type: 'checkbox',
      },
      {
        label: 'Move conversation to open when task completed?',
        key: 'moveToOpenOnComplete',
        default: false,
        type: 'checkbox',
      },
    ],
  },
  clickup: {
    type: INTEGRATION_TYPES.TASK_MANAGER,
    unthreadEnabled: true,
    // isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fclickup.jpg?alt=media&token=80b327a9-43e5-4b06-95cd-fa24c0865553',
    // url: 'clickup',
    key: 'clickup',
    isNango: true,
    registerIntegration: true,
    name: 'ClickUp',
    title: 'Track tasks on ClickUp',
    bullets: [],
    preview:
      'Automatically create ClickUp tasks from your Slack conversations and track statuses from the Unthread inbox',
    connect: 'Connect to ClickUp',
    installable: true,
    userInput: [
      {
        label: 'Post task status changes to triage thread?',
        key: 'postStatusToTriage',
        default: true,
        type: 'checkbox',
      },
      {
        label: 'Move conversation to open when task completed?',
        key: 'moveToOpenOnComplete',
        default: false,
        type: 'checkbox',
      },
    ],
  },
  // strac: {
  //   type: INTEGRATION_TYPES.PRIVACY,
  //   unthreadEnabled: true,
  //   isBeta: true,
  //   imageSrc:
  //     'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fstrac.jpg?alt=media&token=2db326d4-9e15-4910-ad8f-2074e77809ff',
  //   // url: 'strac',
  //   key: 'strac',
  //   name: 'Data Redaction (by Strac)',
  //   title: 'Limit PII with Strac',
  //   bullets: [
  //     "Post events & reminders to your community's Slack channel",
  //     'Share posts and other updates instantly',
  //     'Sync your Slack members with your community member list',
  //   ],
  //   preview:
  //     'Redact sensitive information like PII & PHI from Slack messages to reduce exposure of important files, passwords, and more',
  //   connect: 'Connect to Strac',
  //   installable: false,
  // },
  zendesk: {
    type: INTEGRATION_TYPES.TICKETING,
    unthreadEnabled: true,
    // isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fzendesk.jpg?alt=media&token=8b01c8d2-318c-4935-b5d6-af0978044fb2',
    // url: 'zendesk',
    key: 'zendesk',
    isNango: true,
    registerIntegration: true,
    name: 'Zendesk',
    title: 'Create tickets in Zendesk',
    bullets: [],
    preview: 'Create Zendesk tickets from your Slack conversations and sync replies back to Slack.',
    connect: 'Connect to Zendesk',
    userInput: [
      {
        label: 'Zendesk Subdomain',
        key: 'subdomain',
        required: true,
        isNangoConfig: true,
      },
      {
        label: 'Sync all conversations to Zendesk?',
        key: 'autoSyncNewConversations',
        default: true,
        type: 'checkbox',
      },
    ],
    documentation: 'https://docs.unthread.io/integrations/zendesk',
    installable: true,
  },
  freshservice: {
    type: INTEGRATION_TYPES.TICKETING,
    unthreadEnabled: true,
    isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Ffreshservice.jpg?alt=media&token=b4944078-8bea-4cb2-b6c1-50a3d10729b3',
    key: 'freshservice',
    name: 'Freshdesk & Freshservice',
    title: 'Create tickets in Freshservice',
    bullets: [],
    preview: 'Create Freshservice tickets from your Slack conversations and sync replies back to Slack.',
    connect: 'Connect',
    installable: false,
  },
  salesforce: {
    type: INTEGRATION_TYPES.CRM,
    unthreadEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fsalesforce.jpg?alt=media&token=8b0a7148-0ca3-44da-bd2f-099054f972d3',
    key: 'salesforce',
    isNango: true,
    registerIntegration: true,
    name: 'Salesforce',
    title: 'Push activity to Salesforce',
    bullets: [],
    preview: 'Sync accounts from Salesforce and push activity from Slack to Salesforce.',
    connect: 'Connect to Salesforce',
    documentation: 'https://docs.unthread.io/integrations/salesforce',
    userInput: [
      {
        label:
          'Slack Channel field in Salesforce (to enable auto-syncing). This can be the channel ID or channel name.',
        key: 'slackChannelId',
        placeholder: 'Slack_Channel_ID__c',
        required: false,
      },
      {
        label:
          'Update Slack Channel ID in Salesforce? Check this if you want to update the Slack channel ID in Salesforce when manually linking customers from Unthread.',
        key: 'syncBackSlackChannelId',
        default: true,
        type: 'checkbox',
      },
      {
        label:
          'Using Account Teams in Salesforce? Check this if you use the "Account Teams" feature in Salesforce and want to sync primary and secondary support reps based on the account team roles.',
        key: 'useAccountTeams',
        default: true,
        type: 'checkbox',
      },
      {
        label: 'Sync Primary Support Rep from Salesforce?',
        key: 'syncPrimarySupportRep',
        default: true,
        type: 'checkbox',
      },
      {
        label:
          'Field in Salesforce that maps to the primary support rep in Unthread (e.g. Owner or Primary_Support_Rep__c).',
        key: 'primarySupportRepField',
        required: false,
        default: 'Owner',
        hide: (config) => config.useAccountTeams || !config.syncPrimarySupportRep,
      },
      {
        label: 'Account team role in Salesforce that maps to the primary support rep in Unthread.',
        key: 'primarySupportRepTeamRole',
        required: false,
        default: 'Account Manager',
        hide: (config) => !config.useAccountTeams || !config.syncPrimarySupportRep,
      },
      {
        label: 'Sync Secondary Support Rep from Salesforce?',
        key: 'syncSecondarySupportRep',
        default: false,
        type: 'checkbox',
      },
      {
        label:
          'Field in Salesforce that maps to the secondary support rep in Unthread. (e.g. Owner or Secondary_Support_Rep__c)',
        key: 'secondarySupportRepField',
        required: false,
        hide: (config) => config.useAccountTeams || !config.syncSecondarySupportRep,
      },
      {
        label: 'Account team role in Salesforce that maps to the secondary support rep in Unthread.',
        key: 'secondarySupportRepTeamRole',
        required: false,
        placeholder: 'Account Coordinator',
        hide: (config) => !config.useAccountTeams || !config.syncSecondarySupportRep,
      },
    ],
    installable: true,
  },
  notion: {
    type: INTEGRATION_TYPES.KNOWLEDGEBASE,
    unthreadEnabled: true,
    isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fnotion.png?alt=media&token=a5d5e1df-597a-4d9e-b91a-61bb1d81c9c6',
    key: 'notion',
    name: 'Notion',
    title: 'Sync your documentation from your Notion workspace into Unthread for AI-generated responses.',
    bullets: [],
    installable: true,
    isNango: true,
    registerIntegration: true,
    preview: 'Sync your documentation from your Notion workspace into Unthread for AI-generated responses.',
    connect: 'Connect to Notion',
    userInput: [
      {
        label: 'Is only for internal use?',
        key: 'isInternal',
        default: true,
        type: 'checkbox',
      },
    ],
  },
  confluence: {
    type: INTEGRATION_TYPES.KNOWLEDGEBASE,
    unthreadEnabled: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fconfluence.png?alt=media&token=c163c30a-1bbb-411c-a057-4a12cfd67dd2',
    key: 'confluence',
    name: 'Confluence',
    title: 'Sync your docs and wiki pages from your Confluence database into Unthread for AI-generated responses.',
    bullets: [],
    preview: 'Sync your docs and wiki pages from your Confluence database into Unthread for AI-generated responses.',
    connect: 'Connect to Conflence',
    installable: true,
    isNango: true,
    registerIntegration: true,
    userInput: [
      {
        label: 'Page IDs (comma-separated)',
        key: 'pageIdsAllowlist',
        placeholder: '134322,245344,356855',
        required: true,
        helper:
          'Enter the top-level page ID of any pages or folders to sync. To find a page ID, look in the URL for a short number.',
      },
    ],
  },
  googleDrive: {
    type: INTEGRATION_TYPES.KNOWLEDGEBASE,
    unthreadEnabled: true,
    isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fdrive.jpg?alt=media&token=8f5e3bda-6736-4ef0-b506-3361f3e3b58f',
    key: 'google-drive',
    name: 'Google Drive',
    title: 'Sync your docs and wiki pages from Google Drive into Unthread for AI-generated responses.',
    bullets: [],
    preview: 'Sync your docs and wiki pages from Google Drive into Unthread for AI-generated responses.',
    connect: 'Connect to Google Drive',
    installable: true,
    isNango: true,
    registerIntegration: true,
    userInput: [
      {
        label: 'Shared Drive IDs (comma-separated)',
        key: 'sharedDriveIds',
        placeholder: '134322,245344,356855',
        required: false,
        helper:
          'Enter the IDs of any shared drive you want to sync from. To find a shared drive ID, look in the URL for text that looks like 0AFNOnJ9Mo62XUk9PVA.',
      },
    ],
  },
  workos: {
    type: INTEGRATION_TYPES.HRIS,
    unthreadEnabled: true,
    isBeta: true,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fdirectory.jpg?alt=media&token=aade776b-615f-41cb-a68c-856f6d694308',
    key: 'workos',
    name: 'HR Directory Sync',
    title:
      'Sync employee records with an HR system like Workday, Okta, PeopleSoft, Azure AD, and others for real-time updates.',
    bullets: [],
    preview:
      'Sync employee records with an HR system like Workday, Okta, PeopleSoft, Azure AD, and others for real-time updates.',
    connect: 'Connect',
    installable: true,
    isNango: false,
    registerIntegration: true,
  },
  // pagerduty: {
  //   unthreadEnabled: true,
  //   isBeta: true,
  //   imageSrc:
  //     'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fapps%2Fpagerduty.jpg?alt=media&token=5ae4301c-868d-46e9-92e6-f407af44f7f0',
  //   url: 'pagerduty',
  //   key: 'pagerduty',
  //   name: 'PagerDuty',
  //   title: 'Track Incidents and On-Call Teams with PagerDuty',
  //   bullets: [
  //     "Post events & reminders to your community's Slack channel",
  //     'Share posts and other updates instantly',
  //     'Sync your Slack members with your community member list',
  //   ],
  //   unthreadPreview:
  //     'Create new incidents in PagerDuty from Slack conversations, and manage ticket assignment based on PagerDuty rotation schedules',
  //   html: ``,
  //   connect: 'Connect to Hubspot',
  // },
};

export const USER_STATUS_ACTIVE = 'active';
export const USER_STATUS_INACTIVE = 'inactive';

export const DISCOVERABILITY_SETTINGS = {
  NONE: 'none',
  ADMIN: 'admin',
  ALL: 'all',
};

export const OUTBOUND_TYPE = {
  EMAIL: 'email',
  SLACK: 'slack',
};

export const TEMPLATE_TYPE = {
  EMAIL: 'email',
  SLACK: 'slack',
};

export const PLACEHOLDER_IMAGE_SRC =
  'https://firebasestorage.googleapis.com/v0/b/trnout-co.appspot.com/o/assets%2Fno-image.jpeg?alt=media&token=6728d1b3-66ed-4d8e-8527-88e35a8fd1c8';

export const UPLOAD_TYPE = {
  MEMBER_LIST: 'member-list',
  PROFILE_PIC: 'profile-pic',
};

export const OUTBOUND_LEVEL = {
  SYSTEM: 'system',
  TENANT: 'tenant',
  COMMUNITY: 'community',
};

export const OUTBOUND_STATUS = {
  DRAFT: 'draft',
  SENT: 'sent',
  SCHEDULED: 'scheduled',
  FAILED: 'failed',
};

export const OUTBOUND_RECIPIENT_TYPE = {
  EMAIL: 'email',
  COMMUNITY: 'community',
  USER: 'user',
};

export const OUTBOUND_SENDER_TYPE = {
  SELF: 'user',
  SUPPORT_REP: 'support-rep',
};

export const COMMUNITY_MEMBER_SOURCE_TYPE = {
  ALL_PEOPLE: 'all-people',
};

export const CURRENCIES = {
  USD: {
    code: 'USD',
    name: 'US Dollar',
  },
  EUR: {
    code: 'EUR',
    name: 'European Euro',
  },
  GBP: {
    code: 'GBP',
    name: 'British Pound',
  },
  JPY: {
    code: 'JPY',
    name: 'Japanese Yen',
  },
  CHP: {
    code: 'CHP',
    name: 'Swiss Franc',
  },
  AUD: {
    code: 'AUD',
    name: 'Australian Dollar',
  },
  CAD: {
    code: 'CAD',
    name: 'Canadian Dollar',
  },
  CNY: {
    code: 'CNY',
    name: 'Chinese Yuan',
  },
  NZD: {
    code: 'NZD',
    name: 'New Zealand Dollar',
  },
  INR: {
    code: 'INR',
    name: 'Indian Rupee',
  },
  BZR: {
    code: 'BZR',
    name: 'Brazilian Rupee',
  },
  SEK: {
    code: 'SEK',
    name: 'Swedish Krona',
  },
  ZAR: {
    code: 'ZAR',
    name: 'South African Rand',
  },
  HKD: {
    code: 'HKD',
    name: 'Hong Kong Dollar',
  },
  MXN: {
    code: 'MXN',
    name: 'Mexican Peso',
  },
};

export const REMINDER_TYPE = {
  EMAIL_ALL: 'email-all',
  EMAIL_UNRESPONDED: 'email-unresponded',
  SLACK: 'slack',
  TEAMS: 'teams',
  DEFAULT: 'default',
};

export const CONVERSATION_STATUS = {
  OPEN: 'open',
  IN_PROGRESS: 'in_progress',
  ON_HOLD: 'on_hold',
  CLOSED: 'closed',
};

export const CONVERSATION_CATEGORY = {
  ISSUE: 'issue',
  FEATURE: 'feature',
  QUESTION: 'question',
  NONE: 'none',
};

export const CONVERSATION_PRIORITY_VALUES = {
  LOW: 3,
  MEDIUM: 5,
  HIGH: 7,
  CRITICAL: 9,
};

export const CONVERSATION_PRIORITY_LABEL_MAP = {
  [CONVERSATION_PRIORITY_VALUES.LOW]: 'Low',
  [CONVERSATION_PRIORITY_VALUES.MEDIUM]: 'Medium',
  [CONVERSATION_PRIORITY_VALUES.HIGH]: 'High',
  [CONVERSATION_PRIORITY_VALUES.CRITICAL]: 'Critical',
};

export const SUPPORT_STEP_TYPES = {
  ASSIGNMENT: 'assignment',
  ESCALATION: 'escalation',
  REMINDER: 'reminder',
  TRIAGE: 'triage',
  ADD_FOLLOWER: 'addFollower',
  ADD_COLLABORATOR: 'addCollaborator',
  CREATE_APPROVAL_REQUEST: 'createApprovalRequest',
};

export const SUPPORT_STEP_ASSIGNEE_TYPES = {
  USER: 'user',
  TEAM: 'team',
};

export const SUPPORT_STEP_KEYWORDS = {
  CREATED_BY_USER: 'created-by-user',
  PRIMARY_SUPPORT_ASSIGNEE: 'primary-support-assignee',
  SECONDARY_SUPPORT_ASSIGNEE: 'secondary-support-assignee',
  ASSIGNEE_MANAGER: 'assignee-manager',
  SUBMITTER_MANAGER: 'submitter-manager',
};

export const DEFAULT_INITIAL_SUPPORT_STEP = {
  type: SUPPORT_STEP_TYPES.ASSIGNMENT,
  assigneeId: SUPPORT_STEP_KEYWORDS.PRIMARY_SUPPORT_ASSIGNEE,
  assigneeType: SUPPORT_STEP_ASSIGNEE_TYPES.USER,
  minutes: 0,
};

export const DEFAULT_SUPPORT_STEPS = [
  DEFAULT_INITIAL_SUPPORT_STEP,
  {
    type: SUPPORT_STEP_TYPES.TRIAGE,
    minutes: 0,
  },
];

export const EXTERNAL_CHAT_CONNECTION_TYPES = {
  MICROSOFT_TEAMS: 'microsoft-teams',
  SLACK: 'slack',
};

export const FEATURES = {
  INBOUND_EMAIL: 'inboundEmail',
  SERVICE_ACCOUNTS: 'serviceAccounts',
  OUTBOUND_EMAIL: 'outboundEmail',
  OUTBOUND_SLACK: 'outboundSlack',
  MULTISTEP_ASSIGNMENT_RULES: 'multiStepAssignmentRules',
  WIDGET: 'widget',
  ANALYTICS: 'analytics',
  WEBHOOKS: 'webhooks',
  KNOWLEDGE_BASE_SYNC: 'knowledgeBaseSync',
  WHITELABELED_AUTORESPONDER: 'whitelabeledAutoresponder',
  CRM_INTEGRATIONS: 'crmIntegrations',
  EXTERNAL_TASK_MANAGERS: 'externalTaskManagers',
  EXTERNAL_CHAT_CONNECTIONS: 'externalChatConnections',
  AUTOMATIONS: 'automations',
};

export const STRIPE_PRODUCTS = {
  FREE: {
    name: 'Free',
    stripeProductId: environment.stripePlans.free.productId,
    defaultPriceId: environment.stripePlans.free.priceId,
    userLimit: 1,
  },
  BASIC: {
    name: 'Basic',
    stripeProductId: environment.stripePlans.basic.productId,
    defaultPriceId: environment.stripePlans.basic.priceIdMax10,
    userLimit10: {
      stripePriceId: environment.stripePlans.basic.priceIdMax10,
      base: '150',
    },
    userLimit25: {
      stripePriceId: environment.stripePlans.basic.priceIdMax25,
      base: '200',
    },
    userLimit50: {
      stripePriceId: environment.stripePlans.basic.priceIdMax50,
      base: '250',
    },
    base: '150',
    bullets: [
      {
        text: '500 conversations per month',
      },
      {
        text: 'Conversation tracking & assignment',
      },
      {
        text: 'Slack Inbox access',
      },
      {
        text: 'SLA and response analytics',
      },
      {
        text: 'Mass Slack outbounding',
      },
      {
        text: 'In-app Chat Widget',
      },
      {
        text: 'Basic integrations with API or Zapier',
      },
      {
        text: 'Shared email inboxes',
        disabled: true,
      },
      {
        text: 'CRM integrations',
        disabled: true,
      },
      {
        text: 'Task manager integrations',
        disabled: true,
      },
      {
        text: 'Webhooks',
        disabled: true,
      },
      {
        text: 'AI-generated responses ✨',
        disabled: true,
      },
    ],
    userLimit: 50,
    restrictedFeatures: [
      FEATURES.OUTBOUND_EMAIL,
      FEATURES.INBOUND_EMAIL,
      FEATURES.WEBHOOKS,
      FEATURES.WHITELABELED_AUTORESPONDER,
      FEATURES.KNOWLEDGE_BASE_SYNC,
      FEATURES.CRM_INTEGRATIONS,
      FEATURES.EXTERNAL_CHAT_CONNECTIONS,
      FEATURES.AUTOMATIONS,
    ],
  },
  PRO: {
    name: 'Pro',
    stripeProductId: environment.stripePlans.pro.productId,
    stripeVariablePriceId: environment.stripePlans.pro.priceIdMax10,
    userLimit10: {
      stripePriceId: environment.stripePlans.pro.priceIdMax10,
      base: '250',
    },
    userLimit25: {
      stripePriceId: environment.stripePlans.pro.priceIdMax25,
      base: '300',
    },
    userLimit50: {
      stripePriceId: environment.stripePlans.pro.priceIdMax50,
      base: '500',
    },
    base: '250',
    bullets: [
      {
        text: 'Unlimited conversations',
      },
      {
        text: 'Conversation tracking & assignment',
      },
      {
        text: 'Slack Inbox access',
      },
      {
        text: 'SLA and response analytics',
      },
      {
        text: 'Mass Slack outbounding',
      },
      {
        text: 'In-app Chat Widget',
      },
      {
        text: 'Basic integrations with API or Zapier',
      },
      {
        text: 'Shared email inboxes',
      },
      {
        text: 'CRM integrations',
      },
      {
        text: 'Task manager integrations',
      },
      {
        text: 'Webhooks',
      },
      {
        text: 'AI-generated responses ✨',
      },
    ],
    userLimit: 50,
    restrictedFeatures: [FEATURES.OUTBOUND_EMAIL, FEATURES.AUTOMATIONS],
  },
  CONTACT: {
    name: 'Contact us',
    stripeProductId: null,
    bullets: [
      {
        text: 'Custom pricing that scales with you',
      },
      {
        text: 'High volume channel discounts',
      },
      {
        text: 'Custom integrations, SSO, & more',
      },
      {
        text: 'Dedicated account manager',
      },
    ],
    disabled: false,
    restrictedFeatures: [FEATURES.OUTBOUND_EMAIL],
  },
};

export const REPLY_TIMEOUT_MINUTES_DEFAULT = 180;

export const BOT_HANDLING = {
  OFF: 'off',
  ALL: 'all',
};

export const EXTERNAL_TASK_MANAGEMENT_NAMES = {
  ASANA: 'Asana',
  LINEAR: 'Linear',
  CLICKUP: 'ClickUp',
  HEIGHT: 'Height',
  SHORTCUT: 'Shortcut',
  JIRA: 'JIRA',
  GITHUB: 'GitHub',
  MONDAY: 'Monday.com',
};

export const UNTHREAD_SEND_EXTERNAL_PREFIX = '/unthread';
export const UNTHREAD_SEND_EXTERNAL_CC = 'cc';
export const UNTHREAD_SEND_EXTERNAL_BCC = 'bcc';
export const UNTHREAD_SEND_EXTERNAL_SEND = 'send';

export const MESSAGE_SOURCE_TYPE = {
  SLACK: 'slack',
  EMAIL: 'email',
  WIDGET: 'widget',
  DASHBOARD: 'dashboard',
  TEAMS: 'microsoft-teams',
  SLACK_BOT_DM: 'slack-bot-dm',
};

export const EXTERNAL_TASK_MANAGEMENT_TYPES = {
  ASANA: 'asana',
  LINEAR: 'linear',
  CLICKUP: 'clickup',
  HEIGHT: 'height',
  SHORTCUT: 'shortcut',
  JIRA: 'jira',
  GITHUB: 'github',
  MONDAY: 'monday',
} as const;

export const EXTERNAL_KNOWLEDGE_BASE_TYPES = {
  WEB: 'web',
  CONFLUENCE: 'confluence',
  GOOGLE_DRIVE: 'google-drive',
  NOTION: 'notion',
};

export const INBOX_SORT = {
  CREATED_AT_DESCENDING: 'createdAtDesc',
  CREATED_AT_ASCENDING: 'createdAtAsc',
  PRIORITY_DESCENDING: 'priorityDesc',
  PRIORITY_ASCENDING: 'priorityAsc',
  UPDATED_AT_DESCENDING: 'updatedAtDesc',
  LAST_ACTIVITY_AT_DESCENDING: 'lastActivityAtDesc',
};

export const AUTO_ADD_BOT_CONDITION = {
  ALWAYS: 'always',
  NEVER: 'never',
  ASK: 'ask',
};

export const AUTO_ADD_BOT_CHANNEL_TYPE = {
  ALL: 'all',
  SHARED: 'shared',
};

export const AUTOMATION_RUN_STATUSES = {
  PENDING: 'pending',
  RUNNING: 'running',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELED: 'canceled',
};

export enum INBOX_LAYOUT_TYPES {
  GRID = 'grid',
  SIDEBAR = 'sidebar',
  TABLE = 'table',
}
